import React from "react";
import _ from "lodash";

import { useIntl } from "gatsby-plugin-react-intl";
import PreviewCompatibleImage from "@components/PreviewCompatibleImage";

import { formatIntl } from "@utils/intl";
import "./index.sass";

export const BioCardPlaceholder = ({ className }) => (
    <div className={`bio-card placeholder${className ? " " + className : ""}`}></div>
)

const BioCard = ({ image, slug, intl_title, className }) => {
    const intl = useIntl();
    const finalImage = _.isArray(image) ? image[0] : image 
    const imageLength = _.isArray(image) ? image.length : 0
    const finalTitle = formatIntl(intl, intl_title);
    return (
        <a target="_blank" href={slug} className={`bio-card${className ? " " + className : ""}${imageLength > 0 ? " multi" : ""}`}>
            <div className={`bio-card-img-wrapper`}>
                <PreviewCompatibleImage className="bio-card-img" imageInfo={{
                    image: finalImage,
                    alt: finalTitle
                }} />
            </div>
        </a>
    )
};

export default BioCard;