import { graphql } from "gatsby";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";

import Layout from "@components/Layout";
import BioCard from "@components/BioCard";

import logoAvatar from "@img/logo-avatar.png";
import { postToProduct } from "@utils/product";
import { SOCIAL_MEDIA, OFFICIAL_WEBSITE } from "@utils/const";

import "./index.sass";

const CONTENTS_PER_ROW = 16;

const BioPage = ({ data }) => {
    const { allMarkdownRemark } = data;
    const productLinks = allMarkdownRemark.nodes.filter(node => node.frontmatter.templateKey === "ProductPage").map(postToProduct);
    const [contentOffset, setContentOffset] = useState(Math.min(CONTENTS_PER_ROW, productLinks.length));
    const hasMoreContent = contentOffset < productLinks.length
    
    const handleLoadMoreContent = () => {
        let newOffset = 0;
        if (newOffset < productLinks.length) {
            newOffset = Math.min(contentOffset + CONTENTS_PER_ROW, productLinks.length);
        }
        setContentOffset(newOffset);
    }

    return (
        <Layout noHeader noFooter noChatPopup>
            <div className="bio-page">
                <div className="bio-page-bg bg-1"></div>
                <div className="bio-page-bg bg-2"></div>
                <div className="bio-page-bg bg-3"></div>
                <div className="bio-page-top-content">
                    <div className="bio-page-logo">
                        <img src={logoAvatar} alt="Hiloday" className="bio-page-logo-img"/>
                    </div>
                    <h1 className="bio-page-title">Hiloday</h1>
                    <h2 className="bio-page-subtitle">Your Travel Companion to the Hi and Low</h2>
                    <div className="bio-page-social-media">
                        {Object.keys(SOCIAL_MEDIA).map(key => (
                            <a target="_blank" href={SOCIAL_MEDIA[key].link} className={`bio-page-social-media-item ${key}`}/>
                        ))}
                    </div>
                    <div className="bio-page-button-container">
                        <a target="_blank" href={OFFICIAL_WEBSITE} className="bio-page-button">
                            Hiloday Website
                        </a>
                    </div>
                </div>
                <div className="bio-page-bottom-content">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMoreContent}
                        hasMore={hasMoreContent}
                        loader={<div className="search-widget-result-loader"></div>}>
                        {productLinks.slice(0, contentOffset).map(({ intl_title, images, slug }, index) => (
                            <BioCard key={`${index}-${slug}`} image={images} slug={OFFICIAL_WEBSITE + slug} intl_title={intl_title} />
                        ))}
                    </InfiniteScroll>
                </div>
            </div>
        </Layout>
    );
}

export default BioPage;


export const bioPageQuery = graphql`
    query bioPage {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    templateKey: {
                        in: ["ProductPage"]
                    }
                    IS_HIDDEN: {
                        ne: true
                    }
                    IS_DEFAULT: {
                        ne: true
                    }
                }
            }
        ) {
            nodes {
                id
                fields {
                    slug
                }
                frontmatter {
                    templateKey
                    intl_title {
                        en
                        id
                    }
                    images {
                        childImageSharp {
                            gatsbyImageData(quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
                        }
                    }
                }
            }
        }
    }
`;